import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { QRCodeSVG } from 'qrcode.react';
import { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link, useHistory, useParams } from 'react-router-dom/';

import fireDb from '../firebase';

import Spinner from '../components/Spinner';
import ContentImage from '../components/start/ContentImage';
import PdfViewer from '../components/start/PdfViewer';
import ProgressBar from '../components/start/ProgressBar';
import setMiroLink from '../helper/setMiroLink';

import paperplane from '../assets/icons/paperplane.png';

const ShareRoomView = (props) => {
  const { area } = useParams();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [contentMedia, setContentMedia] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);

  const [flow, setFlow] = useState({});
  const [progress, setProgress] = useState(0);
  const [qrCodeSize, setQrCodeSize] = useState();
  const [countDownMode, setCountDownMode] = useState(true);
  const history = useHistory();
  const workspace = props.match.params.workspace;
  const flowId = props.match.params.id;
  const liveItem = flow?.live?.activeAction;
  const startTimeOfCurrentFlow = flow.live?.start;

  const [flowLiveStatus, setFlowLiveStatus] = useState(flow?.live?.status);
  const [activeFlowItem, setActiveFlowItem] = useState(flow?.live?.activeAction);
  const [activeRoomItem, setActiveRoomItem] = useState(null);
  const [nextFlowItem, setNextFlowItem] = useState(flow?.live?.nextItem);

  const [areaData, setAreaData] = useState(flow?.live?.areas?.[area] || []);
  const [isBreakoutSession, setIsBreakoutSession] = useState(false);

  const calculateProgress = useCallback(() => {
    let progress = 0;
    let timeElapsed = liveItem?.duration - flow?.live?.durationLeft;

    if (flow?.live?.status === 'playing') {
      const dateOfNextJob = moment(flow.live?.dateOfNextJob);
      const diff = moment().diff(dateOfNextJob.clone().subtract(flow.live.durationLeft, 'seconds'), 'seconds');
      const currentDuration = moment.duration(diff);
      let timePlaying = currentDuration._milliseconds;
      timeElapsed += timePlaying;

      progress = (timeElapsed / liveItem.duration) * 100;
    } else {
      progress = (timeElapsed / liveItem?.duration) * 100;
    }

    if (isNaN(progress) || progress > 100) progress = 0;

    return progress;
  }, [flow.live, flow.byId]);

  const formatStartDate = (startTime, secondsToAdd) => {
    const startDate = new Date(startTime);
    startDate.setSeconds(startDate.getSeconds() + secondsToAdd);
    const formattedTime = startDate.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return formattedTime;
  };

  useEffect(() => {
    const id = setInterval(() => {
      setProgress(calculateProgress());
    }, 1000);
    return () => clearInterval(id);
  }, [calculateProgress]);

  useEffect(() => {
    fireDb.child(`${workspace}/flows/${flowId}`).on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        const firebaseData = snapshot.val();
        setFlow({ ...firebaseData });
        console.log('FLOW', flow);
      } else {
        history.push('/');
        setFlow({});
      }
    });
    return () => {
      setFlow({});
    };
  }, [flowId, workspace, history]);

  useEffect(() => {
    if (window.innerWidth < 1200 || contentMedia?.fileType) {
      setQrCodeSize(100);
    } else if (window.innerWidth < 2000) {
      setQrCodeSize(250);
    } else {
      setQrCodeSize(600);
    }
  });

  useEffect(() => {
    setFlowLiveStatus(flow?.live?.status);
    setActiveFlowItem(flow?.live?.activeAction);
    setNextFlowItem(flow?.live?.nextItem);

    setAreaData(flow?.live?.areas?.[area] || []);

    if (activeFlowItem?.area === area || activeFlowItem?.type === 'breakoutSession') {
      if (activeFlowItem?.type === 'breakoutSession') {
        setIsBreakoutSession(true);
        setActiveRoomItem(activeFlowItem.children.find((child) => child.area === area));
      } else {
        setIsBreakoutSession(false);
        setActiveRoomItem(activeFlowItem);
      }
    } else {
      setActiveRoomItem(null);
    }
  }, [flow, area, activeFlowItem]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const FlowNotStarted = ({ flow }) => {
    return (
      <div>
        <img src={paperplane} alt='paperplane' className='w-40 mx-auto mb-5 embeded:hidden' />
        <h1 className='text-6xl font-bold text-center text-white'>{flow.name}</h1>
        <h1 className='text-2xl font-bold text-center text-white'>
          starts at: {moment(flow?.startingDate).format('DD.MM.YYYY – HH:mm')}
        </h1>
      </div>
    );
  };

  const BackgroundImage = ({ url }) => {
    return url ? (
      <img
        className='absolute inset-0 object-cover w-full h-full opacity-80'
        src={url}
        alt='Background'
      />
    ) : null;
  };

  const QRCodeSection = ({ qrCodeSize }) => {
    return (
      <div className='hidden md:absolute md:h-1/4 md:w-1/4 md:right-0 md:flex md:justify-center md:items-center'>
        <QRCodeSVG
          size={qrCodeSize}
          bgColor='transparent'
          value={window.location.href}
          fgColor='#ffffff'
        />
      </div>
    );
  };

  const NextFlowInfo = ({ nextFlowItem, small, startTimeOfCurrentFlow }) => {
    return (
      windowSize.height > 500 && (
        <div className='absolute left-0 right-0 mx-auto'>
          <h2
            className={`mx-auto mt-56 text-lg text-center embeded:hidden ${
              !small && 'sm:text-2xl'
            }`}
          >
            {nextFlowItem ? `Up Next: ${nextFlowItem.name}` : 'No More Events'}
          </h2>
          <h2
            className={`mx-auto mt-1 text-lg text-center embeded:hidden ${!small && 'sm:text-2xl'}`}
          >
            {nextFlowItem
              ? `${formatStartDate(startTimeOfCurrentFlow, flow?.live?.durationLeft)} ${
                  nextFlowItem.area ? 'in ' + nextFlowItem.area : ''
                }`
              : ''}
          </h2>
        </div>
      )
    );
  };

  const LiveDisplayHeader = ({ small, room, isBreakoutSession }) => {
    return (
      <div className='absolute left-0 right-0 mx-auto'>
        <h1
          className={`mt-5 mx-auto text-3xl text-center embeded:text-sm ${!small && 'sm:text-5xl'}`}
        >
          Live: {room.name}
        </h1>
        <h1 className={`mx-auto text-xl text-center embeded:text-sm ${!small && 'sm:text-xl'}`}>
          {isBreakoutSession &&
            room.children.map((child, index) => <div key={index}>{child.name}</div>)}
        </h1>
      </div>
    );
  };

  const BreakDisplay = ({ small }) => {
    return (
      <h1 className={`mx-auto text-3xl text-center embeded:text-sm ${!small && 'text-8xl'}`}>
        BREAK IN AREA
      </h1>
    );
  };

  const Divider = (hight) => {
    return <div className={`h-${hight}`}></div>;
  };

  const TimeLabels = ({ small }) => {
    return (
      windowSize.height > 500 && (
        <div
          className={`flex gap-1 justify-between p-2 text-lg embeded:mx-auto ${
            !small && 'sm:text-2xl'
          }`}
        >
          <span>Hours</span>
          <span>Minutes</span>
          <span>Seconds</span>
        </div>
      )
    );
  };

  const NormalView = ({ small }) => {
    return (
      <>
        <div className='flex flex-col items-center justify-center h-full text-white bg-bgColor bg-op'>
          <BackgroundImage url={backgroundImage?.url} />

          <QRCodeSection qrCodeSize={qrCodeSize} />

          <div className='z-10'>
            {activeFlowItem && flowLiveStatus === 'playing' ? (
              <>
                {activeRoomItem ? (
                  <div
                    onClick={(e) => setCountDownMode((prev) => !prev)}
                    className='mx-auto cursor-pointer'
                  >
                    <Moment
                      className={`font-bold text-center ${
                        windowSize.width < 900 ? 'text-5xl' : 'text-9xl'
                      } ${windowSize.height < 500 && windowSize.width < 900 && 'text-8xl'}`}
                      interval={1000}
                      duration={
                        countDownMode ? moment(flow.live.dateOfNextJob) : moment(flow.live?.start)
                      }
                      format='hh:mm:ss'
                      durationFromNow
                    ></Moment>

                    <TimeLabels small={small} />

                    <Divider hight={10} />

                    <LiveDisplayHeader
                      small={small}
                      room={activeRoomItem}
                      isBreakoutSession={isBreakoutSession}
                    />
                  </div>
                ) : (
                  <BreakDisplay small={small} />
                )}

                <Divider hight={10} />

                <NextFlowInfo
                  nextFlowItem={nextFlowItem}
                  small={small}
                  startTimeOfCurrentFlow={startTimeOfCurrentFlow}
                />
              </>
            ) : (
              <>
                {activeFlowItem && flowLiveStatus === 'paused' ? (
                  <div className='z-10'>
                    {activeRoomItem ? (
                      <div
                        onClick={(e) => setCountDownMode((prev) => !prev)}
                        className='mx-auto cursor-pointer'
                      >
                        <h1
                          className={`font-bold text-center ${
                            windowSize.width < 900 ? 'text-5xl' : 'text-9xl'
                          } ${windowSize.height < 500 && windowSize.width < 900 && 'text-8xl'}`}
                        >
                          -
                          {moment
                            .utc(Math.floor(flow?.live?.durationLeft) * 1000)
                            .format('HH:mm:ss')}
                        </h1>

                        <TimeLabels small={small} />

                        <Divider hight={10} />

                        <LiveDisplayHeader
                          small={small}
                          room={activeRoomItem}
                          isBreakoutSession={isBreakoutSession}
                        />
                      </div>
                    ) : (
                      <BreakDisplay small={small} />
                    )}

                    <Divider hight={10} />

                    <NextFlowInfo
                      nextFlowItem={nextFlowItem}
                      small={small}
                      startTimeOfCurrentFlow={startTimeOfCurrentFlow}
                    />
                  </div>
                ) : (
                  <FlowNotStarted flow={flow} />
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  // Update Document title to <Area Name>
  useEffect(() => {
    document.title = `${area && area}`;
  }, [area]);

  if (isEmpty(flow)) return <Spinner />;

  return (
    <>
      <div className='flex flex-col w-screen h-screen bg-bgColor bg-op'>
        {/* PROGRESS BAR FOR SPECIFIC ROOM */}
        {activeRoomItem && <ProgressBar progress={progress} backgroundImage={backgroundImage} />}

        {/* AREA NAME IF PRESENT */}
        <div className='absolute top-10 left-1/2 transform -translate-x-1/2 text-5xl font-bold text-center text-white z-10 w-full'>
          {area ? `${area}` : 'Global'}
        </div>

        {windowSize.height > 500 && (
          <>
            {/* LINK TO GLOBAL */}
            <div className='absolute top-28 left-1/2 transform -translate-x-1/2 text-lg z-10'>
              <Link
                className='m-1 font-bold text-plum bg-white p-2 whitespace-nowrap overflow-wrap break-word'
                to={`/${workspace}/${flowId}/${flow.name}/share`}
              >
                Übersicht
              </Link>
            </div>
          </>
        )}

        {/* EVENTS COUNT FOR ROOM */}
        {/* <div className='absolute top-10 right-10 text-xl text-white z-10'>
          Total Events: {areaData.length}
        </div> */}

        {contentMedia?.url ? (
          <>
            <div
              className={`z-50 embeded:hidden ${
                contentMedia && contentMedia.contentScreenSize === 'full'
                  ? 'h-full w-full'
                  : 'flex h-2/3 w-full justify-center'
              } `}
            >
              <div
                className={`z-50 flex flex-col justify-center h-full m-auto p-4 ${
                  contentMedia && contentMedia.contentScreenSize === 'full' ? 'w-full' : 'w-5/6'
                } `}
              >
                {contentMedia?.fileType === 'miro' && (
                  <iframe
                    title='Miro Link'
                    src={setMiroLink(contentMedia.url)}
                    bgColor='transparent'
                    width='100%'
                    height='100%'
                  />
                )}
                {contentMedia?.fileType === 'pdf' && (
                  <div className='w-full h-full'>
                    <PdfViewer data={contentMedia} handlePdfPageTurn={false} />
                  </div>
                )}
                {contentMedia?.fileType === 'image' && <ContentImage url={contentMedia.url} />}
              </div>
            </div>
            <div
              className={` embeded:h-full ${
                contentMedia && contentMedia.contentScreenSize === 'full' ? 'hidden w-0' : 'h-1/3'
              } `}
            >
              <NormalView small={true} />
            </div>
          </>
        ) : (
          <NormalView small={false} />
        )}
      </div>
    </>
  );
};

export default ShareRoomView;
