import { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './index.css';

import useStore from './store/UserStore';

import {
  AdminRoute,
  ExportPage,
  FlowtomatorApp,
  Library,
  Login,
  notFound,
  PrivateRoute,
  Register,
  PrivateRegistration,
  ShareRoomView,
  ShareView,
} from './pages';
import Spinner from './components/Spinner';

// context providers
import {
  ActionContextProvider,
  AppContextProvider,
  ComboContextProvider,
  FlowContextProvider,
  TrackContextProvider,
} from './contexts';
import Home from './pages/Home';

function App(props) {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const setUserDetails = useStore((state) => state.setUserDetails);

  const verifyUser = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/api/users/refreshToken', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        refreshToken: localStorage.getItem('refreshToken'),
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          const refreshToken = await response.headers.get('refreshToken');
          localStorage.setItem('refreshToken', refreshToken);
          setUser(data.token);
        } else {
          setUser(null);
        }
        setTimeout(verifyUser, 5 * 60 * 1000);
      })
      .catch((err) => console.log(err));
  }, [setUser]);

  // fetch User Details
  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/api/users/me', {
      method: 'GET',
      credentials: 'include',
      // Pass authentication token as bearer token in header
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserDetails(data);
      } else {
        setUserDetails(null);
      }
    });
  }, [user.token, setUserDetails]);

  useEffect(() => {
    if (user.token) {
      fetchUserDetails();
    }
  }, [fetchUserDetails, user.token]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  return (
    <AppContextProvider>
      <FlowContextProvider user={user}>
        <ComboContextProvider>
          <ActionContextProvider>
            <TrackContextProvider>
              {user.token === null ? (
                <>
                  <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/register' component={PrivateRegistration} />

                    <Route
                      exact
                      path='/:workspace/:id/:name/share/:area'
                      component={ShareRoomView}
                    />
                    <Route exact path='/:workspace/:id/:name/share' component={ShareView} />
                    <Route exact path='/404' component={notFound} />
                    <Redirect to='/404' />
                  </Switch>
                </>
              ) : !user.details ? (
                <Spinner />
              ) : (
                <>
                  {/* {user.details && <Menu user={user} />} */}
                  <Switch>
                    <PrivateRoute exact path='/' component={FlowtomatorApp} />
                    <PrivateRoute exact path='/start' component={FlowtomatorApp} />
                    <PrivateRoute exact path='/flow/:id/:name' component={FlowtomatorApp} />
                    <PrivateRoute exact path='/flow/:id/:name/export' component={ExportPage} />
                    <PrivateRoute exact path='/library' component={Library} />
                    <AdminRoute exact path='/signup' component={Register} />
                    <PrivateRoute
                      exact
                      path='/:workspace/:id/:name/share/:area'
                      component={ShareRoomView}
                    />
                    <PrivateRoute exact path='/:workspace/:id/:name/share' component={ShareView} />
                    <Route exact path='/404' component={notFound} />
                    <Redirect to='/404' />
                  </Switch>
                </>
              )}
            </TrackContextProvider>
          </ActionContextProvider>
        </ComboContextProvider>
      </FlowContextProvider>
    </AppContextProvider>
  );
}

export default App;
