import React, { useState, useCallback, useEffect } from 'react';
import '../../scrubber.css';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Scrubber } from 'react-scrubber';
import { FaPlay, FaPause, FaStepForward, FaStepBackward, FaWindowMinimize } from 'react-icons/fa';

import moment, { duration } from 'moment';
import 'moment-timezone';
import Moment from 'react-moment';
import { secondsToMMMSS } from '../../helper/timeFormaters';
import { flattenFlowWithIds, flattenFlowWithObjects } from '../../helper/flowFlatteners';

// context
import { useAppContext, useFlowContext, useActionContext } from '../../contexts';

const Player = () => {
  const { socket, setPlayerVisible, setNotesVisible } = useAppContext();
  const { flow, flowId, timeDate, userWorkspace: workspace, } = useFlowContext();
  const { setActiveActionId } = useActionContext();

  const [countDownMode, setCountDownMode] = useState(true);
  const [progress, setProgress] = useState(0);
  const [scrubberDuration, setScrubberDuration] = useState(0);
  const [isScrubbing, setIsScrubbing] = useState(false);

  const liveItem = flow?.live?.activeAction;

  const flatFlowWithIds = flattenFlowWithIds(flow);

  // Player Controls
  const playItem = (workspace, flowId) => {   

    socket.emit('api/live/playItem', { 
      workspace, 
      flowId, 
      timeDate
    });
    
    // console.log('Flow live (play item):', flow.live)
  };

  const playPrevItem = () => {
    if (flow.live.prevItem) {    
      const index = flatFlowWithIds.indexOf(flow.live.prevItem.id);

      socket.emit('api/live/goto', { 
        workspace,
        flowId,
        index: index,
      });
      
    //   console.log('Flow live (play prev item):', flow.live)
    }
  };

  const playNextItem = () => {
    if (flow.live.nextItem) {
      const index = flatFlowWithIds.indexOf(flow.live.nextItem.id);

      socket.emit('api/live/goto', { 
        workspace,
        flowId,
        index: index,
      });
      
    //   console.log('Flow live (play next item):', flow.live)
    }
  };

  const pauseAction = (workspace, flowId) => {
    socket.emit('api/live/stop', { 
      workspace: workspace,
      flowId: flowId,
    });
  };

  const handlePlayButtonClick = () => {
    if (flow?.live?.status === 'playing') {
      setNotesVisible(false);
      pauseAction(workspace, flowId);
    } else {
      // setNotesVisible(true);
      setActiveActionId(flow?.live?.lastActiveAction);
      playItem(workspace, flowId);
    }    
    
    // console.log('Flow live (play button click):', flow.live)
  };

  const calculateProgress = useCallback(() => {
    let progress = 0;
    let timeElapsed = liveItem?.duration - flow?.live?.durationLeft;

    if (flow?.live?.status === 'playing') {
      const dateOfNextJob = moment(flow.live?.dateOfNextJob);
      const diff = moment().diff(dateOfNextJob.clone().subtract(flow.live.durationLeft, 'seconds'), 'seconds');
      const currentDuration = moment.duration(diff);
      let timePlaying = currentDuration._milliseconds;
      timeElapsed += timePlaying;

      progress = (timeElapsed / liveItem.duration) * 100;
    } else {
      progress = (timeElapsed / liveItem?.duration) * 100;
    }

    if (isNaN(progress) || progress > 100) progress = 0;

    return progress;
  }, [flow.live, flow.byId]);

  useEffect(() => {
    if (!isScrubbing) {
      const id = setInterval(() => {
        setProgress(calculateProgress());
      }, 1000);
      return () => clearInterval(id);
    }
  }, [calculateProgress, isScrubbing]);

  const progressStyle = {
    width: `${progress}%`,
    transition: 'width 1000ms linear',
  };

  const handleScrubStart = (value) => {
    setIsScrubbing(true);
  };

  const handleScrubChange = (scrubbedTo) => {
    const updatedProgress = (scrubbedTo / liveItem.duration) * 100;
    setProgress(Math.floor(updatedProgress));
    setScrubberDuration(secondsToMMMSS(Math.floor(scrubbedTo)));
  };

  const scrubBreakoutSession = (scrubbedTo) => {
    const tracks = flow.live.tracks || null;

    // Check which track item should be live and set the correct duration left on it
    // if (tracks) {
    //   tracks.forEach(liveTrack => {

    //   })
    // }


    if (tracks) {
      liveItem.children.forEach((track, trackIndex) => {
        const brsDurationLeft = Math.round(liveItem.duration - scrubbedTo);
        const brsTimeElapsed = liveItem.duration - brsDurationLeft;

        let sumDuration = 0;
        track.children.some((trackItem, trackItemIndex) => {

          sumDuration += trackItem.duration;
          const prevItem = track.children[trackItemIndex - 1] || null;
          const nextItem = track.children[trackItemIndex + 1] || null;

          if (sumDuration >= brsTimeElapsed) {
            let durationLeft = sumDuration - brsTimeElapsed;
            // if (durationLeft > 100) durationLeft = trackItem.duration;
            const newJobStartDate = new Date((new Date()).getTime() + durationLeft * 1000).toISOString(); 

            tracks[track.id] = {
              ...tracks[track.id],
              jobId: trackItem.id,
              trackIndex: trackIndex,
              activeAction: trackItem,
              activeActionIndex: trackItemIndex,
              prevItem: prevItem,
              nextItem: nextItem,
              duration: trackItem.duration,
              durationLeft: durationLeft,
              start: newJobStartDate,
              newJobStartDate: newJobStartDate,
              status: flow.live.status,
            }

            return true;
          } else if (!nextItem) {
            // Delete track and the track jobs
            tracks[track.id] = { 
              id: track.id,
              status: 'ended' 
            };
          }

          if (trackItemIndex === track.children.length) delete tracks[track.id];

          return false;
        });
      });
    }

    return tracks;
  }

  const handleScrubEnd = (scrubbedTo) => {
    // Play next action if scrubbed over the duration of the active item
    if (scrubbedTo >= flow?.live?.activeAction?.duration) {
      playNextItem();
      setIsScrubbing(false);
      return;
    }

    // const timeElapsed = liveItem.duration - flow.live.durationLeft;
    const durationLeft =  Math.round(liveItem.duration - scrubbedTo);
    // Difference is the time between the timeElapsed and the scrubbedTo
    // const difference = timeElapsed - scrubbedTo;

    const activeActionIndex = flattenFlowWithIds(flow).indexOf(liveItem.id);

    socket.emit('api/live/scrubto', { 
      workspace: workspace,
      flowId: flowId,
      activeActionIndex: activeActionIndex,
      newJobStartDate: new Date((new Date()).getTime() + durationLeft * 1000).toISOString(),
      durationLeft: durationLeft,
      tracks: (flow?.live?.activeAction?.type === 'breakoutSession') ? scrubBreakoutSession(scrubbedTo) : null,
    });

    setIsScrubbing(false);
  };

  return (
    <motion.div
      initial={{ translateY: 200 }}
      animate={{ translateY: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.4 }}
      exit={{ translateY: 200 }}
    >
      <div className='absolute z-30 flex items-center justify-around h-20 px-6 text-gray-900 bg-white border bottom-2 left-10 right-10'>
        
        {/* {flow.live.tracks?.length > 0 &&  */}
          {/* <div className='flex flex-row absolute top-[-35%] left-10'>
            <div 
              className='flex gap-1 border border-dark bg-white text-sm text-dark rounded-md px-1 cursor-pointer mr-2'
              onClick={() => setActivePlayer('')}
            >
              {flow.live.activeAction.name}
            </div>
            {Object.entries(flow.live.tracks).reverse().map(([key, value], index) => {
                return <div
                  key={`player-tab-${index}`} 
                  className='flex gap-1 border border-dark bg-white text-sm text-dark rounded-md px-1 cursor-pointer mr-2'
                  onClick={() => setActivePlayer(key)}
                >
                  {value.activeAction.name}
                </div>
              })
            }
          </div> */}
        {/* } */}
        
        {/* progressbar */}
        <div
          className='absolute inset-0 transition bg-bgColorLight -z-20'
          style={progressStyle}
        ></div>

        <div className='flex-1'>
            <div className='font-bold text-center'>
              <h1>
                {flow.live.activeAction?.name || flow.byId?.[flow.byOrder[0]]?.name}
              </h1>
            </div>
        </div>
        <div className='absolute left-0 flex items-center h-8 bg-black rounded-t-lg -top-8 bg-opacity-40 backdrop-blur-sm'>
          <button
            className='p-2 mb-2 text-gray-200 border-gray-100 hover:text-yellow-400'
            onClick={() => setPlayerVisible(prev => !prev)}
          >
            <FaWindowMinimize size={15} />
          </button>
        </div>

        {/* Controls */}
        <div className='flex justify-center flex-1 w-48 gap-4 border-l-2 border-r-2 border-bgColor text-bgColor'>
          {isScrubbing ? (
            <h1 className='text-2xl font-bold text-black'>{scrubberDuration}</h1>
          ) : (
            <>
              {/* PLAY PREVIOUS ACTION */}
              <button onClick={playPrevItem}>
                <FaStepBackward size={30} />
              </button>
              {/* PLAY / PAUSE BUTTON */}
              <button onClick={handlePlayButtonClick}>
                {flow?.live?.status === 'playing' ? <FaPause size={30} /> : <FaPlay size={30} />}
              </button>
              {/* PLAY NEXT ACTION */}
              <button onClick={playNextItem}>
                <FaStepForward size={30} />
              </button>
            </>
          )}
        </div>

        {liveItem && flow?.live?.status === 'playing' ? (
          <>
            <div
              className='flex justify-center flex-1 font-bold cursor-pointer'
              onClick={(e) => setCountDownMode((prev) => !prev)}
            >
              <Moment
                interval={1000}
                // duration={
                //   countDownMode ? moment(flow.live.dateOfNextJob) : moment(startTimeOfCurrentAction)
                // }
                duration={
                  countDownMode ? moment(flow.live.dateOfNextJob) : moment.utc(Math.floor(flow?.live?.durationLeft) * 1000).format('HH:mm:ss')
                }
                format='hh:mm:ss'
                durationFromNow
              ></Moment>
              <span className='mx-2'> – </span>
              <h1>{secondsToMMMSS(liveItem?.duration)} </h1>
            </div>
          </>
        ) : flow?.live?.status === 'paused' ? (
          <div className='flex justify-center flex-1 font-bold'>
            {/* -{moment.utc(flow.live.dateOfNextJob).format('HH:mm:ss')} */}
            <h1>-{secondsToMMMSS(Math.floor(flow.live.durationLeft))}</h1>
            <span className='mx-2'> – </span>
            <h1>{secondsToMMMSS(liveItem?.duration)}</h1>
          </div>
        ) : (
          <div className='flex justify-center flex-1 font-bold'>
            {flow?.live?.status === 'over' ? (
              <div>end</div>
            ) : (
              <>
                <h1>00:00:00</h1>
                <span className='mx-2'> – </span>
                <h1>{secondsToMMMSS(flow.byId[flow?.live?.lastActiveAction]?.duration || 0)} </h1>
              </>
            )}
          </div>
        )}
        <div
          className='absolute bottom-0 scrubber-container'
          style={{ height: '20px', width: '100%' }}
        >
          <Scrubber
            min={0}
            max={
              flow?.live?.activeAction?.duration
            }
            value={
              Math.round((flow?.live?.activeAction?.duration * progress) / 100)
            }
            onScrubStart={handleScrubStart}
            onScrubEnd={handleScrubEnd}
            onScrubChange={handleScrubChange}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Player;
